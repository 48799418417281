import { Stocks } from '@domain/common/enums'

import { CreateBinanceCommissionUseCase } from '@domain/stocks/binance/commission'

import { GetCommissionUseCase } from '../get-use-case'
import { GetDiscountCommissionUseCase } from '../get-discount-use-case'

import type { ICreateCommissionUseCase } from '@domain/stocks/interfaces/commission'
import type { IPairDTO } from '@domain/stocks/pair'

class CreateCommissionUseCase {

  public static new (stock: Stocks, list: IPairDTO[]): ICreateCommissionUseCase {
    const useCases: Record<Stocks, CreateBinanceCommissionUseCase> = {
      [Stocks.BINANCE]: new CreateBinanceCommissionUseCase(
        GetCommissionUseCase.new(Stocks.BINANCE, list),
        GetDiscountCommissionUseCase.new(Stocks.BINANCE)
      ),
      [Stocks.BINANCE_TESTNET]: new CreateBinanceCommissionUseCase(
        GetCommissionUseCase.new(Stocks.BINANCE_TESTNET, list),
        GetDiscountCommissionUseCase.new(Stocks.BINANCE_TESTNET)
      ),
      [Stocks.OKX]: new CreateBinanceCommissionUseCase(
        GetCommissionUseCase.new(Stocks.BINANCE_TESTNET, list),
        GetDiscountCommissionUseCase.new(Stocks.BINANCE_TESTNET)
      )
    }

    return useCases[stock]
  }

}

export { CreateCommissionUseCase }
