import { Intervals } from '@domain/stocks/trade-history'

import type { OKXIntervals } from '../../types'

const transformIntervalToOKX = (interval: Intervals): OKXIntervals => {
  switch (interval) {
    case Intervals.m1: return 'candle1m'
    case Intervals.m3: return 'candle3m'
    case Intervals.m5: return 'candle5m'
    case Intervals.m15: return 'candle15m'
    case Intervals.m30: return 'candle30m'
    case Intervals.h1: return 'candle1H'
    case Intervals.h2: return 'candle2H'
    case Intervals.h4: return 'candle4H'
    case Intervals.h6: return 'candle6H'
    case Intervals.h12: return 'candle12H'
    case Intervals.d1: return 'candle1D'
    case Intervals.d3: return 'candle3D'
    case Intervals.w1: return 'candle1W'
    case Intervals.M1: return 'candle1M'
    default: return 'candle1m'
  }
}

export { transformIntervalToOKX }
