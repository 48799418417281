import { BinanceDealDivisionUseCase } from '@domain/stocks/binance/deal'
import { DealRepository } from '@data/repository/stocks/controllers/deal'
import { Stocks } from '@domain/common/enums'

import type { IDealDivisionUseCase } from '@domain/stocks/interfaces/deal'

class DealDivisionUseCase {

  private readonly _useCases: Record<Stocks, IDealDivisionUseCase> = {
    [Stocks.BINANCE]: new BinanceDealDivisionUseCase(DealRepository.new(Stocks.BINANCE)),
    [Stocks.BINANCE_TESTNET]: new BinanceDealDivisionUseCase(DealRepository.new(Stocks.BINANCE_TESTNET)),
    [Stocks.OKX]: new BinanceDealDivisionUseCase(DealRepository.new(Stocks.BINANCE_TESTNET)),
  }

  public static new (stock: Stocks): IDealDivisionUseCase {
    return new DealDivisionUseCase()._useCases[stock]
  }

}

export { DealDivisionUseCase }
