import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { UpdateOrderUseCase } from '../../../_'

import type { IOrderRepository, IUpdateOrderUseCase, IUpdateOrderPort } from '../../../../common'

class UpdateOKXOrderUseCase extends UpdateOrderUseCase implements IUpdateOrderUseCase {

  constructor (repository: IOrderRepository) {
    super(repository)

    this.countDigitsMultiplier = 10000000000000
  }

  public override async execute (port: IUpdateOrderPort): Promise<boolean> {
    const errors = this.validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }

    return this.repository.updateOrder(port)
  }

  protected override validateStep (value: string, min: string, step: string): boolean {
    if (!step.includes('.')) {
      return !value.includes('.')
    }

    return super.validateStep(value, min, step)
  }

}

export { UpdateOKXOrderUseCase }
