import { GetAccountUseCase } from '../../../_'

import type { IGetAccountUseCase, IAccountDTO } from '../../../../common'

class GetOKXAccountUseCase extends GetAccountUseCase implements IGetAccountUseCase {

  public async execute (): Promise<IAccountDTO> {
    return this.repository.getAccount()
  }

}

export { GetOKXAccountUseCase }
