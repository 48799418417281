import { Stocks } from '@domain/common/enums'

import { BinanceReportRepository } from '../stocks'

import type { IReportRepository } from '@domain/stocks/report'

interface IPayload {
  stock: Stocks
}

const initReportRepository = (payload: IPayload): IReportRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceReportRepository('binance-order/report')
    case Stocks.BINANCE_TESTNET: return new BinanceReportRepository('binance-order/report')

    default: return new BinanceReportRepository('binance-order/report')
  }
}

export { initReportRepository }
