enum AuthErrorCode {
  USER_NOT_FOUND = '104',
  USER_NEED_GA = '108',
  USER_ALREADY_ENABLE_GA = '105',
  USER_ALREADY_DISABLE_GA = '106',
  // Пользователь уже подтвержден
  USER_ALREADY_CONFIRMED = '100',
  // Не верный логин или пароль
  INVALID_USER_DATA = '101',
  // Пользователь не прошел верификацию
  INVALID_USER_STATUS = '102',
  // Неправильный код верфикации
  INVALID_USER_CODE = '107',
  // Код уже был отправлен
  USER_CODE_ALREADY_SENDED = '113',

  INVALID_TOKEN = '103',
  INVALID_EMAIL = '201',

  PROPERTY_IS_REQUIRED = '200',
  PROPERTY_IS_INVALID = '202'
}

export { AuthErrorCode }
