import { Stocks } from '@domain/common/enums'

import { BinanceLimitRepository } from '@data/repository/stocks/binance/limit'

import type { ILimitRepository } from '@domain/stocks/interfaces'

class LimitRepository {

  private readonly _repositories: Record<Stocks, ILimitRepository> = {
    [Stocks.BINANCE]: new BinanceLimitRepository('binance-provider'),
    [Stocks.BINANCE_TESTNET]: new BinanceLimitRepository('binance-provider-testnet'),
    [Stocks.OKX]: new BinanceLimitRepository('binance-provider-testnet'),
  }

  public static new (stock: Stocks): ILimitRepository {
    return new LimitRepository()._repositories[stock]
  }

}

export { LimitRepository }
