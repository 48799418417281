import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import type { IContentPort } from '@domain/setting-profile/content/interface/port'

import type {
  IContentDto,
  IContentRepository,
  IUpdateContentUseCase,
  IContentValidation,
  IContentErrors
} from '@domain/setting-profile/content'

class UpdateContentUseCase implements IUpdateContentUseCase {

  private _repository: IContentRepository

  private _validation: IContentValidation

  constructor (repository: IContentRepository, validation: IContentValidation) {
    this._repository = repository
    this._validation = validation
  }

  public async execute (port: IContentPort): Promise<Partial<IContentDto>> {
    this._throwErrors(port)

    await this._repository.updateContent({
      bestAsk: port.bestAsk,
      bestBid: port.bestBid,
      tickPrice: port.tickPrice,
      showForm: port.showForm,
      showMiniTick: port.showMiniTick,
      axisVolume: port.axisVolume,
      defaultGraphType: port.defaultGraphType,
      candleVariant: port.candleVariant,
      candleInterval: port.candleInterval,
      tickVariant: port.tickVariant,
      tickVolumeMode: port.tickVolumeMode,
      widthVolume: Number(port.widthVolume),
      heightVolume: Number(port.heightVolume),
      tickSize: Number(port.tickSize),
      defaultScale: Number(port.defaultScale),
      showVerticalVolume: port.isShowVerticalVolume,
      showHorizontalVolume: port.isShowHorizontalVolume,
      sideHorizontalVolume: port.sideHorizontalVolume,
      scaleChange: port.isMultiplierScale,
      dealHistoryStatus: port.isInputOutputLines,
      measureToolEnable: port.showMeasureTool,
      measureToolInterval: port.measureToolInterval,
      measureToolOpacity: Number(port.measureToolOpacity),
      orderLevelMode: port.orderLevelMode,
      orderBookWidth: Number(port.orderBookWidth)
    })

    return port
  }

  private _throwErrors (port: IContentPort): void {
    const errors = this._validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `Validation error in ${this.constructor.name}`
        },
        data: errors
      })
    }
  }

  private _validatePort (port: IContentPort): IContentErrors {
    const errors: IContentErrors = {}
    const keys = Object.entries(port) as [keyof IContentPort, string][]

    for (const [key, value] of keys) {
      const result = this._validation.validate()[key].run(value).errors?.code

      if (result !== undefined) errors[key] = result
    }

    return errors
  }

}

export { UpdateContentUseCase }
