import { HttpServiceV1 } from '@data/common/services'
import { HttpCode } from '@data/common/enums'
import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { ApiKeyError } from '@domain/stocks/api-key'

import { ApiKeyRepository } from '../../_'

import type { IApiKeyHttpError } from '../../_'

import type {
  IApiKeyListDTO,
  IApiKeyRepository,
  ICreateApiKeyPort,
  IDeleteApiKeyPort,
  ISetActiveApiKeyPort
} from '@domain/stocks/api-key'

import type { IOkxKeyItem } from '../interfaces'

import type { IHttpError } from '@data/common/interfaces'

class OKXApiKeyRepository extends ApiKeyRepository implements IApiKeyRepository {

  public async getApiKeyList (): Promise<IApiKeyListDTO> {
    return HttpServiceV1.get<IOkxKeyItem[] | null>(`/${this.provider}/get-api-key-list`)
      .then((response) => {
        const apiKeyList = response?.map((item) => {
          return {
            id: item.id,
            name: item.title,
            isMain: item.isMain,
            isActive: true,
            publicKey: item.publicKey
          }
        }) ?? []

        return {
          total: apiKeyList.length,
          list: apiKeyList
        }
      })
  }

  public async createApiKey (port: ICreateApiKeyPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/set-api-key`, {
      body: {
        publicKey: port.publicKey,
        privateKey: port.privateKey,
        passphrase: port.passphrase,
        title: port.name
      }
    })
      .then(() => true)
      .catch((error: IHttpError<IApiKeyHttpError>) => {
        let data = error.errors.code
          ? this.transformError(+error.errors.code, error.errors.message)
          : error.errors.pubKey === undefined
            ? InternalCode.SERVER_ERROR
            : this.transformError(+error.errors.pubKey)

        if (['50119', '50105', '50113'].includes(error.errors.message)) data = ApiKeyError.API_KEY_NOT_FOUND

        throw ExceptionService.new({
          status: {
            code: HttpCode.BAD_REQUEST,
            message: 'Backend throw errors'
          },
          data: { logical: data }
        })
      })
  }

  public override async setActiveApiKey (port: ISetActiveApiKeyPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/set-main-api-key`, { body: { Id: port.uuid }})
      .then(() => true)
  }

  public override async deleteApiKey (port: IDeleteApiKeyPort): Promise<boolean> {
    return HttpServiceV1.delete(`/${this.provider}/delete-api-key`, { body: { Id: port.uuid }})
      .then(() => true)
      .catch((error: IHttpError<IApiKeyHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: HttpCode.BAD_REQUEST,
            message: 'Backend throw errors'
          },
          data: { logical: error.errors.code ? error.errors.code : InternalCode.SERVER_ERROR }
        })
      })
  }

}

export { OKXApiKeyRepository }
