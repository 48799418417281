import type { IAuthRepository } from '@domain/auth/interfaces'

import type { IUseCase } from '@domain/common/interfaces'

export class RefreshUseCase implements IUseCase<never, any> {

  private _repository: IAuthRepository

  constructor (repository: IAuthRepository) {
    this._repository = repository
  }

  public async execute (): Promise<any> {
    await this._repository.refresh()
  }

}
