import { Stocks } from '@domain/common/enums'

import { DeleteBinanceApiKeyUseCase, DeleteOKXApiKeyUseCase } from '../../stocks'

import type { IApiKeyRepository, IDeleteApiKeyUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IApiKeyRepository
}

const initDeleteApiKeyUseCase = (payload: IPayload): IDeleteApiKeyUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new DeleteBinanceApiKeyUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new DeleteBinanceApiKeyUseCase(payload.repository)
    case Stocks.OKX: return new DeleteOKXApiKeyUseCase(payload.repository)

    default: return new DeleteBinanceApiKeyUseCase(payload.repository)
  }
}

export { initDeleteApiKeyUseCase }
