import { Stocks } from '@domain/common/enums'

import { CreateBinanceOrderUseCase, CreateOKXOrderUseCase } from '../../stocks'

import type { IOrderRepository, ICreateOrderUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IOrderRepository
}

const initCreateOrderUseCase = (payload: IPayload): ICreateOrderUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new CreateBinanceOrderUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new CreateBinanceOrderUseCase(payload.repository)
    case Stocks.OKX: return new CreateOKXOrderUseCase(payload.repository)

    default: return new CreateBinanceOrderUseCase(payload.repository)
  }
}

export { initCreateOrderUseCase }
