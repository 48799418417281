import { LengthValidator, RegexValidator, RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import type { IUseCasePortValidator } from '@domain/common/interfaces/use-case'

import type { ICreateApiKeyPort, IDeleteApiKeyPort, ISetActiveApiKeyPort } from '../../../common'

const CREATE_OKX_API_KEY_VALIDATOR: IUseCasePortValidator<ICreateApiKeyPort> = {
  name: new ValidationChain([
    new RequiredValidator(),
    new LengthValidator({ min: 1, max: 255 }),
    new RegexValidator(/[а-яёА-ЯЁa-zA-Z0-9()\s-]/g, true)
  ]),
  publicKey: new ValidationChain([
    new RequiredValidator(),
    new RegexValidator(/[a-z0-9-]/g, true),
    new LengthValidator({ min: 36, max: 36 })
  ]),
  privateKey: new ValidationChain([
    new RequiredValidator(),
    new RegexValidator(/[A-Z0-9]/g, true),
    new LengthValidator({ min: 32, max: 32 })
  ]),
  passphrase: new ValidationChain([
    new RequiredValidator(),
    new LengthValidator({ min: 8, max: 32 }),
    new RegexValidator(/[a-zA-Z0-9`~!@#$%^&*()_\-+=\]\[{}\\|;':"<>,?/]/g, true)
  ])
}

const DELETE_OKX_API_KEY_VALIDATOR: IUseCasePortValidator<IDeleteApiKeyPort> = {
  uuid: new ValidationChain([new RequiredValidator()])
}

const SET_OKX_API_KEY_VALIDATOR: IUseCasePortValidator<ISetActiveApiKeyPort> = {
  uuid: new ValidationChain([new RequiredValidator()])
}

export {
  CREATE_OKX_API_KEY_VALIDATOR,
  DELETE_OKX_API_KEY_VALIDATOR,
  SET_OKX_API_KEY_VALIDATOR
}
