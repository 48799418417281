import { Stocks } from '@domain/common/enums'
import { BinanceDealUnionUseCase } from '@domain/stocks/binance/deal'
import { DealRepository } from '@data/repository/stocks/controllers/deal'

import type { IDealUnionUseCase } from '@domain/stocks/interfaces/deal'

class DealUnionUseCase {

  private readonly _useCases: Record<Stocks, IDealUnionUseCase> = {
    [Stocks.BINANCE]: new BinanceDealUnionUseCase(DealRepository.new(Stocks.BINANCE)),
    [Stocks.BINANCE_TESTNET]: new BinanceDealUnionUseCase(DealRepository.new(Stocks.BINANCE_TESTNET)),
    [Stocks.OKX]: new BinanceDealUnionUseCase(DealRepository.new(Stocks.BINANCE_TESTNET)),
  }

  public static new (stock: Stocks): IDealUnionUseCase {
    return new DealUnionUseCase()._useCases[stock]
  }

}

export { DealUnionUseCase }
