import { GetApiKeyListUseCase } from '../../../_'

import type { IApiKeyListDTO, IGetApiKeyListUseCase } from '../../../../common'

class GetOKXApiKeyListUseCase extends GetApiKeyListUseCase implements IGetApiKeyListUseCase {

  public override async execute (): Promise<IApiKeyListDTO> {
    return this.repository.getApiKeyList()
  }

}

export { GetOKXApiKeyListUseCase }
