import type { IPairDTO } from '@domain/stocks/pair'
import type { ICommissionRepository, ICommissionDTO } from '@domain/stocks/interfaces/commission'

class BinanceTestnetCommissionRepository implements ICommissionRepository {

  private _pairList: IPairDTO[]

  constructor (pairList: IPairDTO[]) {
    this._pairList = pairList
  }

  public async getCommission (): Promise<ICommissionDTO[]> {
    const commission = this._pairList.map((pair) => {
      return {
        symbol: pair.ticker.symbol,
        commission: { taker: '0', maker: '0' }
      }
    })

    return Promise.resolve(commission)
  }

  public async getDiscount (): Promise<string> {
    return Promise.resolve('0')
  }

}

export { BinanceTestnetCommissionRepository }
