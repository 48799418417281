import { LocalStorageService } from '@data/common/services'
import { LocalStorageValues } from '@data/common/enums'
import { ProfileRepository } from '@data/repository/profile'
import { GetProfileInfoUseCase } from '@domain/user/use-case/get-profile-info-use-case'
import { User } from '@domain/user/entity'
import { Stocks } from '@domain/common/enums'

import { LanguageResources } from '@app/services/i18n/language'

import { GetCurrentUserLicenseUseCase } from '@domain/license'

import { LicenseRepository } from '@data/repository/license'

import { initApiKeyRepository } from '@data/repository/stocks/api-key'

import { initGetApiKeyListUseCase } from '@domain/stocks/api-key'

import type { IApiKeyListDTO } from '@domain/stocks/api-key'
import type { IUserLicenseDTO } from '@domain/license'

import type { ICreateUserPayload, IUserEntity, UserResponse } from '@domain/user/interfaces'

import type { IRouterContextProps } from '@app/common/auth'
import type { IUseCase } from '@domain/common/interfaces'

interface ILoadUserData {
  id: IUserEntity['id']
  setUser: IRouterContextProps['setUser']
  setStock: IRouterContextProps['setStock']
}

type UseCase = ReturnType<IUseCase<any, any>['execute']>

const loadApiKeys = async (stock: Stocks): Promise<IApiKeyListDTO> => {
  const repository = initApiKeyRepository({ stock })
  const useCase = initGetApiKeyListUseCase({ stock, repository })

  return useCase.execute()
}

const loadUserData = async ({ id, setUser, setStock }: ILoadUserData): Promise<void> => {
  // TODO: хранится будет на бэке, потом выпилить
  let stock = Stocks.BINANCE

  try {
    stock = LocalStorageService.get<Stocks>(LocalStorageValues.STOCK)

    if (process.env.REACT_APP_STAGE === 'PROD') {
      if (stock === Stocks.BINANCE_TESTNET) {
        stock = Stocks.BINANCE
        LocalStorageService.set<Stocks>(LocalStorageValues.STOCK, Stocks.BINANCE)
      }
    }

    setStock(stock)
  } catch {
    stock = Stocks.BINANCE
    setStock(Stocks.BINANCE)
    LocalStorageService.set<Stocks>(LocalStorageValues.STOCK, Stocks.BINANCE)
  }

  const requests: UseCase[] = [
    new GetProfileInfoUseCase(new ProfileRepository()).execute(),
    loadApiKeys(stock),
    new GetCurrentUserLicenseUseCase(new LicenseRepository()).execute()
  ]

  const userData: Omit<ICreateUserPayload, 'id'> = {
    lastName: '',
    firstName: '',
    middleName: '',

    lang: LanguageResources.RU,
    include2Fa: false,

    role: '',
    apiKeys: [],

    license: null
  }

  return Promise.allSettled(requests)
    .then(async (responses) => {
      if (responses[0].status !== 'rejected') {
        Object.assign(userData, responses[0].value as UserResponse)
      }

      if (responses[1].status !== 'rejected') {
        userData.apiKeys = (responses[1].value as IApiKeyListDTO).list
      }

      if (responses[2].status !== 'rejected') {
        userData.license = responses[2].value as IUserLicenseDTO
      }

      setUser(await User.new({ id, ...userData }))
    })
}

export { loadUserData }
