import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { DeleteApiKeyUseCase } from '../../../_'
import { DELETE_OKX_API_KEY_VALIDATOR } from '../../validators'

import type { IApiKeyRepository, IDeleteApiKeyPort, IDeleteApiKeyUseCase } from '../../../../common'

class DeleteOKXApiKeyUseCase extends DeleteApiKeyUseCase implements IDeleteApiKeyUseCase {

  constructor (repository: IApiKeyRepository) {
    super(repository, DELETE_OKX_API_KEY_VALIDATOR)
  }

  public override async execute (port: IDeleteApiKeyPort): Promise<boolean> {
    const errors = this.validatePort(port)

    if (Object.keys(errors).length) {
      throw ExceptionService.new({
        status: {
          code: InternalCode.VALIDATION_ERROR,
          message: `${this.constructor.name} validation port error`
        },
        data: errors
      })
    }

    return this.repository.deleteApiKey(port)
  }

}

export { DeleteOKXApiKeyUseCase }
