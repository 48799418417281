import { Stocks } from '@domain/common/enums'

import { BinancePairRepository, OKXPairRepository } from '../stocks'

import type { IPairRepository } from '@domain/stocks/pair'

interface IPayload {
  stock: Stocks
}

const initPairRepository = (payload: IPayload): IPairRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinancePairRepository('binance-provider-trade')
    case Stocks.BINANCE_TESTNET: return new BinancePairRepository('binance-provider-trade-testnet')
    case Stocks.OKX: return new OKXPairRepository('okx-provider-pair')

    default: return new BinancePairRepository('binance-provider-trade')
  }
}

export { initPairRepository }
