import { Stocks } from '@domain/common/enums'

import { BinanceOrderRepository, OKXOrderRepository } from '../stocks'

import type { IOrderRepository } from '@domain/stocks/order'

interface IPayload {
  stock: Stocks
}

const initOrderRepository = (payload: IPayload): IOrderRepository => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceOrderRepository('binance-order')
    case Stocks.BINANCE_TESTNET: return new BinanceOrderRepository('binance-provider-order-testnet')
    case Stocks.OKX: return new OKXOrderRepository('okx-provider-order')

    default: return new BinanceOrderRepository('binance-provider-order')
  }
}

export { initOrderRepository }
