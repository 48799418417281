import axios from 'axios'

import { RefreshUseCase } from '@domain/auth/use-case/refresh-use-case'

import { AuthRepository } from '@data/repository/auth'

import { LocalLogoutUseCase } from '@domain/auth/use-case/local-logout-use-case'

import { HttpCode } from '@data/common/enums'

import type { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios'

import type {
  IHttpService,
  IGetPayload,
  IPostPayload,
  IHttpResponse,
  IHttpDroppedRequest
} from '@data/common/interfaces'

class HttpService implements IHttpService {

  protected _instance: AxiosInstance

  private _droppedRequests: IHttpDroppedRequest[]

  private _isRefreshing: boolean

  constructor (config: AxiosRequestConfig) {
    this._isRefreshing = false
    this._droppedRequests = []

    this._instance = axios.create(config)

    this._interceptResponse()
  }

  public setHeaders (key: string, value: string | number | boolean): void {
    this._instance.defaults.headers.common[key] = value
  }

  public async get<Response> (url: string, payload?: IGetPayload): Promise<Response> {
    return this._instance.get(url, { params: payload?.params })
  }

  public async post<Response> (url: string, payload?: IPostPayload): Promise<Response> {
    return this._instance.post(url, payload?.body, {
      params: payload?.params,
      responseType: payload?.responseType
    })
  }

  public async put<Response> (url: string, payload?: IPostPayload): Promise<Response> {
    return this._instance.put(url, payload?.body, { params: payload?.params })
  }

  public async patch<Response> (url: string, payload?: IPostPayload): Promise<Response> {
    return this._instance.patch(url, payload?.body, { params: payload?.params })
  }

  public async delete<Response> (url: string, payload?: IPostPayload): Promise<Response> {
    return this._instance.delete(url, {
      params: payload?.params,
      data: payload?.body
    })
  }

  private _interceptResponse (): void {
    this._instance.interceptors.response.use(
      this._handleResponse.bind(this),
      this._handleErrorResponse.bind(this)
    )
  }

  private async _handleResponse (response: AxiosRequestConfig<IHttpResponse<unknown>>): Promise<unknown> {
    return Promise.resolve(response.data?.data === undefined ? response.data : response.data.data)
  }

  private async _handleErrorResponse (error: AxiosError): Promise<unknown> {
    const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean }

    if (error.response?.status === HttpCode.UNAUTHORIZED && !originalRequest._retry) {
      if (this._isRefreshing) {
        return new Promise((resolve, reject) => {
          this._droppedRequests.push({ resolve, reject })
        })
          .then(async () => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = this._instance.defaults.headers.common.Authorization
            }

            return this._instance(originalRequest)
          })
          .catch(async (err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      this._isRefreshing = true

      const repository = new AuthRepository()
      const refreshUseCase = new RefreshUseCase(repository)
      const logoutUseCase = new LocalLogoutUseCase(repository)

      return new Promise((resolve, reject) => {
        refreshUseCase.execute()
          .then(() => {
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = this._instance.defaults.headers.common.Authorization
            }

            this._processDroppedRequests(false)
            resolve(this._instance(originalRequest))
          })
          .catch(async () => {
            await logoutUseCase.execute()

            this._processDroppedRequests(true)
            reject()
          })
          .finally(() => {
            this._isRefreshing = false
          })
      })
    }

    return Promise.reject(error.response?.data)
  }

  private _processDroppedRequests (isError: boolean): void {
    this._droppedRequests.forEach((item) => {
      isError ? item.reject() : item.resolve()
    })

    this._droppedRequests = []
  }

}

export { HttpService }
