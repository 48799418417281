import { Stocks } from '@domain/common/enums'

import { BinanceDealCancelUseCase } from '@domain/stocks/binance/deal'

import { DealRepository } from '@data/repository/stocks/controllers/deal'

import type { IDealCancelUseCase } from '@domain/stocks/interfaces/deal'

class DealCancelUseCase {

  private readonly _useCases: Record<Stocks, IDealCancelUseCase> = {
    [Stocks.BINANCE]: new BinanceDealCancelUseCase(DealRepository.new(Stocks.BINANCE)),
    [Stocks.BINANCE_TESTNET]: new BinanceDealCancelUseCase(DealRepository.new(Stocks.BINANCE_TESTNET)),
    [Stocks.OKX]: new BinanceDealCancelUseCase(DealRepository.new(Stocks.BINANCE_TESTNET)),
  }

  public static new (stock: Stocks): IDealCancelUseCase {
    return new DealCancelUseCase()._useCases[stock]
  }

}

export { DealCancelUseCase }
