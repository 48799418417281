import { Stocks } from '@domain/common/enums'

import { LimitRepository } from '@data/repository/stocks/controllers'
import { GetBinanceLimitUseCase } from '@domain/stocks/binance/limit'

import type { IGetLimitUseCase } from '@domain/stocks/interfaces'

class GetLimitUseCase {

  private readonly _useCases: Record<Stocks, IGetLimitUseCase> = {
    [Stocks.BINANCE]: new GetBinanceLimitUseCase(LimitRepository.new(Stocks.BINANCE)),
    [Stocks.BINANCE_TESTNET]: new GetBinanceLimitUseCase(LimitRepository.new(Stocks.BINANCE_TESTNET)),
    [Stocks.OKX]: new GetBinanceLimitUseCase(LimitRepository.new(Stocks.BINANCE_TESTNET)),
  }

  public static new (stock: Stocks): IGetLimitUseCase {
    return new GetLimitUseCase()._useCases[stock]
  }

}

export { GetLimitUseCase }
