import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import { MarketOverviewBySymbolSocket } from '../../_'

import type { IOKXMarketOverviewSocketResponse, IOKXSocketInterval } from '../interfaces'
import type { IMarketOverviewBySymbolSocket } from '../../../interfaces'

import type { IMarketOverviewDTO } from '@domain/stocks/market-overview'
import type { IPairDTO } from '@domain/stocks/pair'
import type { Stocks } from '@domain/common/enums'

class OKXMarketOverviewBySymbolSocket
  extends MarketOverviewBySymbolSocket<IOKXMarketOverviewSocketResponse>
  implements IMarketOverviewBySymbolSocket {

  constructor () {
    super('okxMarketWindow')
  }

  public override subscribe (pair: IPairDTO, stock: Stocks): void {
    const symbol = pair.ticker.slash.split('/').join('-')

    this.pair = pair
    this.socket = new CentrifugeService(`${this.channel}_${symbol}`, STOCK_CONNECTION[stock])
  }

  public override onMessage (callback: (message: IMarketOverviewDTO) => void): void {
    this.socket?.onMessage((data) => {
      if (this.pair === null) return

      const interval1M = this._findInterval(data.intervals, '1m')
      const interval3M = this._findInterval(data.intervals, '3m')
      const interval5M = this._findInterval(data.intervals, '5m')
      const interval15M = this._findInterval(data.intervals, '15m')
      const interval30M = this._findInterval(data.intervals, '30m')
      const interval1H = this._findInterval(data.intervals, '1H')
      const interval2H = this._findInterval(data.intervals, '2H')
      const interval4H = this._findInterval(data.intervals, '4H')
      const interval24H = this._findInterval(data.intervals, '1D')

      callback({
        id: this.pair.ticker.slash,
        pair: this.pair,
        price: data.price,
        priceChangePercent_o1m: interval1M?.changePricePercent ?? '0',
        priceChangePercent_o3m: interval3M?.changePricePercent ?? '0',
        priceChangePercent_o5m: interval5M?.changePricePercent ?? '0',
        priceChangePercent_o15m: interval15M?.changePricePercent ?? '0',
        priceChangePercent_o30m: interval30M?.changePricePercent ?? '0',
        priceChangePercent_o1h: interval1H?.changePricePercent ?? '0',
        priceChangePercent_o2h: interval2H?.changePricePercent ?? '0',
        priceChangePercent_o4h: interval4H?.changePricePercent ?? '0',
        priceChangePercent_o24h: interval24H?.changePricePercent ?? '0',
        priceChangePercent_1m: interval1M?.delta ?? '0',
        priceChangePercent_3m: interval3M?.delta ?? '0',
        priceChangePercent_5m: interval5M?.delta ?? '0',
        priceChangePercent_15m: interval15M?.delta ?? '0',
        priceChangePercent_30m: interval30M?.delta ?? '0',
        priceChangePercent_1h: interval1H?.delta ?? '0',
        priceChangePercent_2h: interval2H?.delta ?? '0',
        priceChangePercent_4h: interval4H?.delta ?? '0',
        priceChangePercent_24h: interval24H?.delta ?? '0',
        priceChangePercent_1hOKA: interval1H?.quoteVolume ?? '0',
        priceChangePercent_24hOKA: interval24H?.quoteVolume ?? '0',
        priceChangePercent_1hOBA: interval1H?.mainVolume ?? '0',
        priceChangePercent_24hOBA: interval24H?.mainVolume ?? '0',
        priceChangePercent_1hOPEN: interval1H?.open ?? '0',
        priceChangePercent_24hOPEN: interval24H?.open ?? '0',
        priceChangePercent_1hPick: interval1H?.highest ?? '0',
        priceChangePercent_24hPick: interval24H?.highest ?? '0'
      })
    })
  }

  private _findInterval (array: IOKXSocketInterval[], interval: string): IOKXSocketInterval | null {
    return array.find((item) => item.interval === interval) ?? null
  }

}

export { OKXMarketOverviewBySymbolSocket }
