import { transformSideFromOKX, transformTypeFromOKX, transformStatusFromOKX } from '@data/stocks/okx'

import { OrderSource } from '@domain/stocks/order'

import { OrderSocket } from '../../_'

import type { IOKXOrderSocketResponse, IOKXSocketClassicOrder, IOKXSocketAlgoOrder } from '../interfaces'

import type { Stocks } from '@domain/common/enums'
import type { IOrderDTO } from '@domain/stocks/order'
import type { IPairDTO } from '@domain/stocks/pair'

class OKXOrderSocket extends OrderSocket<IOKXOrderSocketResponse> {

  constructor (stock: Stocks, list: IPairDTO[]) {
    super('okx-orders-stream-', stock, list)
  }

  public override onMessage (callback: (message: IOrderDTO) => void): void {
    this.socket?.onMessage((message) => {
      const order = message.data[0]

      const takeProfit = {
        isInclude: false,
        percent: '1.00'
      }

      const stopLoss = {
        isInclude: false,
        percent: '3.00'
      }

      const pair = this.list.find((item) => item.ticker.symbol === order.instId.split('-').join(''))

      if (pair) {
        const payload: Partial<IOrderDTO> = {
          dealId: 0,
          pair: pair,
          createdDate: Number(order.uTime),
          updatedDate: Number(order.cTime),
          amount: order.sz,
          side: transformSideFromOKX(order.side),
          type: transformTypeFromOKX(order.ordType),
          status: transformStatusFromOKX(order.state),
          source: OrderSource.ORIGIN,
          tradeType: 'SPOT',
          takeProfit: takeProfit,
          stopLoss: stopLoss
        }

        if (message.arg.channel === 'orders') {
          const _order = order as IOKXSocketClassicOrder

          payload.id = _order.ordId
          payload.price = _order.px
          payload.stopPrice = _order.px
          payload.completePercent = (+_order.fillSz * 100 / +_order.sz).toFixed(2)
        }

        if (message.arg.channel === 'orders-algo') {
          const _order = order as IOKXSocketAlgoOrder

          payload.id = _order.algoId
          payload.price = _order.ordPx
          payload.stopPrice = _order.triggerPx
          payload.completePercent = '0'
        }

        callback(payload as IOrderDTO)
      }
    })
  }

}

export { OKXOrderSocket }
