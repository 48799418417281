import { OrderSide, OrderStatus, OrderType } from '@domain/stocks/order'

import { OKXOrderSide, OKXOrderType, OKXOrderStatus } from '../../enums'

const transformSideFromOKX = (side: OKXOrderSide): OrderSide => {
  switch (side) {
    case OKXOrderSide.BUY: return OrderSide.BUY
    case OKXOrderSide.SELL: return OrderSide.SELL
    default: return OrderSide.BUY
  }
}

const transformTypeFromOKX = (side: OKXOrderType): OrderType => {
  switch (side) {
    case OKXOrderType.LIMIT: return OrderType.LIMIT
    case OKXOrderType.MARKET: return OrderType.MARKET
    case OKXOrderType.POST_ONLY: return OrderType.POST_ONLY
    case OKXOrderType.STOP_LIMIT: return OrderType.STOP_LIMIT
    default: return OrderType.LIMIT
  }
}

const transformStatusFromOKX = (side: OKXOrderStatus): OrderStatus => {
  switch (side) {
    case OKXOrderStatus.NEW: return OrderStatus.NEW
    case OKXOrderStatus.TRADE: return OrderStatus.TRADE
    case OKXOrderStatus.COMPLETE: return OrderStatus.COMPLETE
    case OKXOrderStatus.COMPLETE_ALGO: return OrderStatus.COMPLETE
    case OKXOrderStatus.CANCELED: return OrderStatus.CANCELED
    case OKXOrderStatus.FAILED: return OrderStatus.CANCELED
    default: return OrderStatus.NEW
  }
}

const transformSideToOKX = (side: OrderSide): OKXOrderSide => {
  switch (side) {
    case OrderSide.BUY: return OKXOrderSide.BUY
    case OrderSide.SELL: return OKXOrderSide.SELL
    default: return OKXOrderSide.BUY
  }
}

const transformTypeToOKX = (side: OrderType): OKXOrderType => {
  switch (side) {
    case OrderType.LIMIT: return OKXOrderType.LIMIT
    case OrderType.MARKET: return OKXOrderType.MARKET
    case OrderType.POST_ONLY: return OKXOrderType.POST_ONLY
    case OrderType.STOP_LIMIT: return OKXOrderType.STOP_LIMIT
    default: return OKXOrderType.LIMIT
  }
}

export {
  transformSideFromOKX,
  transformTypeFromOKX,
  transformStatusFromOKX,
  transformSideToOKX,
  transformTypeToOKX
}
