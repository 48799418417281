import { omit } from 'lodash'

import { HttpServiceV1, LocalStorageService } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import { LocalStorageValues } from '@data/common/enums'
import { Intervals } from '@domain/stocks/trade-history'

import type { IHttpError } from '@data/common/interfaces'
import type {
  IContentResponse,
  IContentResponseErrors,
  IContentServerError
} from '@data/repository/setting-profile/content/interface'

import type {
  IContentDto,
  IContentErrors,
  IContentRepository,
  IUpdateContentPayload,
} from '@domain/setting-profile/content'

class ContentRepository implements IContentRepository {

  public async getContent (): Promise<IContentDto> {
    return HttpServiceV1.get<IContentResponse>('settings/content')
      .then((response) => {
        return {
          ...omit(response, [
            'showVerticalVolume',
            'showHorizontalVolume',
            'scaleChange',
            'dealHistoryStatus',
            'measureToolEnable',
            'measureToolInterval',
            'measureToolOpacity',
            'orderLevelMode'
          ]),
          showMiniTick: response.showMiniTick,
          candleInterval: this._getCandleInterval(),
          sideHorizontalVolume: response.sideHorizontalVolume,
          isShowVerticalVolume: response.showVerticalVolume,
          isShowHorizontalVolume: response.showHorizontalVolume,
          candleVariant: response.candleVariant,
          tickVariant: response.tickVariant,
          tickVolumeMode: response.tickVolumeMode,
          isMultiplierScale: response.scaleChange,
          isInputOutputLines: response.dealHistoryStatus,
          showMeasureTool: response.measureToolEnable,
          measureToolInterval: response.measureToolInterval,
          measureToolOpacity: response.measureToolOpacity,
          orderLevelMode: response.orderLevelMode,
          orderBookWidth: response.orderBookWidth
        }
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async updateContent (port: IUpdateContentPayload): Promise<boolean> {
    const keys = Object.entries(port) as [keyof IUpdateContentPayload, unknown][]

    for (const [key, value] of keys) {
      if (value === undefined || typeof value === 'number' ? isNaN(value!) : false) delete port[key]
    }

    return HttpServiceV1.put('settings/content', { body: port })
      .then(() => {
        if (port.candleInterval) {
          LocalStorageService.set<Intervals>(LocalStorageValues.CANDLE_INTERVAL, port.candleInterval)
        }

        return true
      })
      .catch((error: IHttpError<IContentResponseErrors | IContentServerError>) => {
        throw this._throwError(error)
      })
  }

  private _getCandleInterval (): Intervals {
    try {
      return LocalStorageService.get<Intervals>(LocalStorageValues.CANDLE_INTERVAL)
    } catch {
      return Intervals.m1
    }
  }

  // eslint-disable-next-line max-len
  private _throwError (error: IHttpError<IContentResponseErrors | IContentServerError>): ExceptionService<IContentErrors> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : InternalCode.VALIDATION_ERROR
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

}

export { ContentRepository }
