import { Stocks } from '@domain/common/enums'

import { CreateBinancePairUseCase, CreateOKXPairUseCase } from '../../stock'

import type { IPairRepository, ICreatePairUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IPairRepository
}

const initCreatePairUseCase = (payload: IPayload): ICreatePairUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new CreateBinancePairUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new CreateBinancePairUseCase(payload.repository)
    case Stocks.OKX: return new CreateOKXPairUseCase(payload.repository)

    default: return new CreateBinancePairUseCase(payload.repository)
  }
}

export { initCreatePairUseCase }
