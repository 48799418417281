enum OKXOrderSide {
  BUY = 'buy',
  SELL = 'sell'
}

enum OKXOrderStatus {
  COMPLETE = 'filled',
  COMPLETE_ALGO = 'effective',
  CANCELED = 'canceled',
  FAILED = 'order_failed',
  TRADE = 'partially_filled',
  NEW = 'live'
}

enum OKXOrderType {
  LIMIT = 'limit',
  MARKET = 'market',
  POST_ONLY = 'post_only',
  STOP_LIMIT = 'trigger'
}

export { OKXOrderSide, OKXOrderStatus, OKXOrderType }
