import { Stocks } from '@domain/common/enums'

import { GetBinanceApiKeyListUseCase, GetOKXApiKeyListUseCase } from '../../stocks'

import type { IApiKeyRepository, IGetApiKeyListUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IApiKeyRepository
}

const initGetApiKeyListUseCase = (payload: IPayload): IGetApiKeyListUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new GetBinanceApiKeyListUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new GetBinanceApiKeyListUseCase(payload.repository)
    case Stocks.OKX: return new GetOKXApiKeyListUseCase(payload.repository)

    default: return new GetBinanceApiKeyListUseCase(payload.repository)
  }
}

export { initGetApiKeyListUseCase }
