import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'
import { deleteLastZero } from '@domain/common/utils'

import { PairRepository } from '../../_'
import { OKXPairState } from '../enums'

import type { IGetPairPort, ITypeLimit, ILimit, IPairDTO, IPairRepository } from '@domain/stocks/pair'

import type { IOKXPair } from '../interface'

class OKXPairRepository extends PairRepository implements IPairRepository {

  public async getPairList (): Promise<IPairDTO[]> {
    return HttpServiceV1.get<IOKXPair[]>(`${this.provider}/pair-list`, {
      params: { tradeType: 'SPOT' }
    })
      .then((response) => {
        return response
          .filter((pair) => pair.state === OKXPairState.LIVE)
          .map((pair) => OKXPairRepository._formatPair(pair))
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Internal Server Error'
          }
        })
      })
  }

  public async getPair (payload: IGetPairPort): Promise<IPairDTO> {
    return HttpServiceV1.get<IOKXPair[]>(`${this.provider}/pair-list`, {
      params: {
        symbol: payload.symbol,
        tradeType: 'SPOT'
      }
    })
      .then((response) => {
        if (response.length > 0) {
          return OKXPairRepository._formatPair(response[0])
        }

        throw ExceptionService.new({
          status: {
            code: InternalCode.PROPERTY_NOT_FOUND,
            message: `pair with symbol ${payload.symbol} no found`
          }
        })
      })
  }

  private static _formatPair (pair: IOKXPair): IPairDTO {
    const limits = OKXPairRepository._formatLimits(pair)

    const mainDigits = OKXPairRepository._getDigits(limits.limit.main.step)
    const lastDigits = OKXPairRepository._getDigits(limits.limit.price.step)

    return {
      ticker: {
        slash: `${pair.baseCcy}/${pair.quoteCcy}`,
        under: `${pair.baseCcy}_${pair.quoteCcy}`,
        symbol: pair.instId.split('-').join('')
      },
      mainAsset: {
        ticker: pair.baseCcy,
        digits: mainDigits
      },
      lastAsset: {
        ticker: pair.quoteCcy,
        digits: lastDigits
      },
      limits: limits
    }
  }

  private static _formatLimits (pair: IOKXPair): ITypeLimit {
    const priceLimits: ILimit = {
      min: pair.tickSz,
      max: pair.maxLmtSz,
      step: pair.tickSz
    }

    return {
      limit: {
        price: priceLimits,
        main: {
          min: pair.minSz,
          max: pair.maxLmtSz,
          step: pair.lotSz
        },
        last: {
          min: pair.tickSz
        }
      },
      market: {
        price: priceLimits,
        main: {
          min: pair.minSz,
          max: pair.maxLmtSz,
          step: pair.lotSz
        },
        last: {
          min: pair.tickSz
        }
      }
    }
  }

  private static _getDigits (step: string): number {
    const _step = deleteLastZero(step)

    return _step.includes('.')
      ? _step.split('.')[1].length
      : 0
  }

}

export { OKXPairRepository }
