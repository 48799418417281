import { Stocks } from '@domain/common/enums'

import { CreateBinanceApiKeyUseCase, CreateOKXApiKeyUseCase } from '../../stocks'

import type { IApiKeyRepository, ICreateApiKeyUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IApiKeyRepository
}

const initCreateApiKeyUseCase = (payload: IPayload): ICreateApiKeyUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new CreateBinanceApiKeyUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new CreateBinanceApiKeyUseCase(payload.repository)
    case Stocks.OKX: return new CreateOKXApiKeyUseCase(payload.repository)

    default: return new CreateBinanceApiKeyUseCase(payload.repository)
  }
}

export { initCreateApiKeyUseCase }
