import { Stocks } from '@domain/common/enums'

import { GetPairUseCase } from '../../stock'

import type { IPairRepository, IGetPairUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IPairRepository
}

const initGetPairUseCase = (payload: IPayload): IGetPairUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new GetPairUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new GetPairUseCase(payload.repository)

    default: return new GetPairUseCase(payload.repository)
  }
}

export { initGetPairUseCase }
