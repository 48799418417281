import { Stocks } from '@domain/common/enums'

import { SetBinanceActiveApiKeyUseCase, SetOKXActiveApiKeyUseCase } from '../../stocks'

import type { IApiKeyRepository, ISetActiveApiKeyUseCase } from '../../common'

interface IPayload {
  stock: Stocks
  repository: IApiKeyRepository
}

const initSetActiveApiKeyUseCase = (payload: IPayload): ISetActiveApiKeyUseCase => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new SetBinanceActiveApiKeyUseCase(payload.repository)
    case Stocks.BINANCE_TESTNET: return new SetBinanceActiveApiKeyUseCase(payload.repository)
    case Stocks.OKX: return new SetOKXActiveApiKeyUseCase(payload.repository)

    default: return new SetBinanceActiveApiKeyUseCase(payload.repository)
  }
}

export { initSetActiveApiKeyUseCase }
