import { GetOKXAccountUseCase } from '../get-account'
import { GetOKXBalanceUseCase } from '../get-balance'
import { CreateAccountUseCase } from '../../../_'

import type { ICreateAccountUseCase, IAccountRepository } from '../../../../common'

class CreateOKXAccountUseCase extends CreateAccountUseCase implements ICreateAccountUseCase {

  constructor (repository: IAccountRepository) {
    const getAccountUseCase = new GetOKXAccountUseCase(repository)
    const getBalanceUseCase = new GetOKXBalanceUseCase(repository)

    super(getAccountUseCase, getBalanceUseCase)
  }

}

export { CreateOKXAccountUseCase }
