import { WebsocketService } from '@data/common/services'

import type { IPairDTO } from '@domain/stocks/pair'
import type { ITradeDTO } from '@domain/stocks/trade-history'

import type { IOKXTradeSocketResponse, IOKXTrade } from '../interfaces'
import type { ITradeSocket } from '../../../interfaces'

class OKXTradeSocket implements ITradeSocket {

  private _socket?: WebsocketService<IOKXTradeSocketResponse>

  private _symbol?: string

  private readonly _delay: number

  constructor (delay?: number) {
    this._delay = delay ?? 100
  }

  public subscribe (pair: IPairDTO, connection: string): void {
    this._socket = new WebsocketService(connection, 'public')

    this._socket.onConnect(() => {
      this._symbol = pair.ticker.slash.split('/').join('-')
      this._socket?.send(JSON.stringify({ op: 'subscribe', args: [{ channel: 'trades', instId: this._symbol }]}))
    })
  }

  public unsubscribe (): void {
    if (this._symbol !== undefined) {
      this._socket?.send(JSON.stringify({ op: 'unsubscribe', args: [{ channel: 'trades', instId: this._symbol }]}))
    }

    this._socket?.close()
  }

  public onMessage (callback: (message: ITradeDTO) => void): void {
    let lastTradeDeal: IOKXTrade | null = null

    this._socket?.onMessage((message) => {
      if (message.data === undefined) return

      if (lastTradeDeal === null || Number(message.data[0].ts) - Number(lastTradeDeal.ts) > this._delay) {
        lastTradeDeal = message.data[0]

        callback({
          id: Number(message.data[0].tradeId),
          symbol: message.data[0].instId.split('-').join(''),
          timestamp: Number(message.data[0].ts),
          price: Number(message.data[0].px),
          amount: Number(message.data[0].sz),
          lastAssetAmount: Number(message.data[0].px) * Number(message.data[0].sz),
          isBuyerMaker: message.data[0].side === 'sell'
        })
      }
    })
  }

}

export { OKXTradeSocket }
