import { HttpServiceV1 } from '@data/common/services'
import { TradeTypes } from '@domain/common/enums'

import { AccountRepository } from '../../_'

import type { IOKXBalanceResponse } from '../interfaces'

import type { IBalanceDTO, IAccountDTO, IAccountRepository } from '@domain/stocks/account'

class OKXAccountRepository extends AccountRepository implements IAccountRepository {

  public override async getAccount (): Promise<IAccountDTO> {
    return Promise.resolve({
      accountType: TradeTypes.SPOT,
      permission: []
    })
  }

  public override async getBalance (): Promise<IBalanceDTO> {
    return HttpServiceV1.get<[IOKXBalanceResponse]>(`/${this.provider}/balance`)
      .then((response) => {
        return {
          balance: response[0].details.map((item) => {
            return {
              asset: item.ccy,
              free: item.availBal,
              locked: item.frozenBal,
              all: item.cashBal
            }
          })
        }
      })
  }

}

export { OKXAccountRepository }
