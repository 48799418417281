import type { IAuthRepository } from '@domain/auth/interfaces'

export class LocalLogoutUseCase {

  private _repository: IAuthRepository

  constructor (repository: IAuthRepository) {
    this._repository = repository
  }

  public async execute (): Promise<void> {
    return this._repository.localLogout()
  }

}
