import { Stocks } from '@domain/common/enums'

import {
  BinanceTestnetCommissionRepository,
  BinanceMainnetCommissionRepository
} from '@data/repository/stocks/binance/commission'

import type { IPairDTO } from '@domain/stocks/pair'
import type { ICommissionRepository } from '@domain/stocks/interfaces/commission'

class CommissionRepository {

  public static new (stock: Stocks, list: IPairDTO[]): ICommissionRepository {
    const repositories: Record<Stocks, ICommissionRepository> = {
      [Stocks.BINANCE]: new BinanceMainnetCommissionRepository(),
      [Stocks.BINANCE_TESTNET]: new BinanceTestnetCommissionRepository(list),
      [Stocks.OKX]: new BinanceTestnetCommissionRepository(list),
    }

    return repositories[stock]
  }

}

export { CommissionRepository }
