import { Stocks } from '@domain/common/enums'

import { BinanceTradeSocket, OKXTradeSocket } from '../stocks'

import type { ITradeSocket } from '../interfaces'

interface IPayload {
  stock: Stocks
  delay?: number
}

const initTradeSocket = (payload: IPayload): ITradeSocket => {
  switch (payload.stock) {
    case Stocks.BINANCE: return new BinanceTradeSocket(payload.delay)
    case Stocks.BINANCE_TESTNET: return new BinanceTradeSocket(payload.delay)
    case Stocks.OKX: return new OKXTradeSocket(payload.delay)

    default: return new BinanceTradeSocket(payload.delay)
  }
}

export { initTradeSocket }
