import { CookieService, HttpServiceV1, HttpServiceV2, JwtService } from '@data/common/services'

import { CookieValues, HttpCode, HttpHeaders } from '@data/common/enums'

import { ExceptionService } from '@domain/common/services'

import { AuthErrorCode } from '@data/repository/auth/enums'

import { InternalCode, ValidationCode } from '@domain/common/enums'

import type { IUserEntity } from '@domain/user/interfaces'

import type { IResendVerifyCodePayload } from '@domain/auth/interfaces/repository'

import type { IHttpError } from '@data/common/interfaces/http'

import type {
  IHttpAuthError,
  IHttpAuthLogicalError,
  IRegistrationResponse
} from '@data/repository/auth/interfaces'

import type {
  AuthPayload,
  ConfirmRegistrationPayload,
  IAuthErrors,
  IAuthRepository,
  IRegistrationDTO,
  IRegistrationPayload,
  RecoveryPasswordPayload
} from '@domain/auth/interfaces'

class AuthRepository implements IAuthRepository {

  public async auth (payload: AuthPayload): Promise<IUserEntity['id']> {
    return HttpServiceV1.post<string>('/auth/login', { body: payload })
      .then((response) => {
        this._setAccessToken(response)

        return JwtService.encode<{ uid: IUserEntity['id'] }>(response).uid
      })
      .catch((error: IHttpError<IHttpAuthError | IHttpAuthLogicalError>) => {
        throw (error.errors as IHttpAuthLogicalError).message
          ? this._throwLogicalError(error.errors as IHttpAuthLogicalError)
          : this._throwValidationError(error.errors as IHttpAuthError)
      })
  }

  public async registration (payload: IRegistrationPayload): Promise<IRegistrationDTO> {
    return HttpServiceV1.post<IRegistrationResponse>('/auth/register', { body: payload })
      .then((response) => {
        return {
          email: response.email,
          password: payload.password
        }
      })
      .catch((error: IHttpError<IHttpAuthError | IHttpAuthLogicalError>) => {
        throw (error.errors as IHttpAuthLogicalError).message
          ? this._throwLogicalError(error.errors as IHttpAuthLogicalError)
          : this._throwValidationError(error.errors as IHttpAuthError)
      })
  }

  public async resendCode (port: IResendVerifyCodePayload): Promise<boolean> {
    return HttpServiceV1.post('auth/resend-mail', { body: { ...port, mailType: 1 }})
      .then(() => true)
      .catch((error: IHttpError<IHttpAuthError>) => {
        throw this._throwValidationError(error.errors)
      })
  }

  public async confirmRegistration (payload: ConfirmRegistrationPayload): Promise<IUserEntity['id']> {
    return HttpServiceV1.post<string>('/auth/confirm-register', { body: payload })
      .then((response) => {
        this._setAccessToken(response)

        return JwtService.encode<{ uid: IUserEntity['id'] }>(response).uid
      })
      .catch((error: IHttpError<IHttpAuthError | IHttpAuthLogicalError>) => {
        throw this._throwValidationError(error.errors as IHttpAuthError)
      })
  }

  public async recoveryPassword (payload: RecoveryPasswordPayload): Promise<void> {
    return HttpServiceV1.post('', { body: payload })
  }

  public async refresh (): Promise<boolean> {
    return HttpServiceV1.post<string>('/auth/refresh')
      .then((response) => {
        this._setAccessToken(response)

        return true
      })
  }

  public async logout (): Promise<void> {
    return HttpServiceV1.post('/auth/logout')
      .then(() => {
        CookieService.remove(CookieValues.ACCESS_TOKEN)
      })
      .catch(() => {
        CookieService.remove(CookieValues.ACCESS_TOKEN)
      })
  }

  public async localLogout (): Promise<void> {
    CookieService.remove(CookieValues.ACCESS_TOKEN)
    return Promise.resolve()
  }

  private _setAccessToken (token: string): void {
    HttpServiceV1.setHeaders(HttpHeaders.AUTHORIZATION, `Bearer ${token}`)
    HttpServiceV2.setHeaders(HttpHeaders.AUTHORIZATION, `Bearer ${token}`)

    CookieService.set<string>(CookieValues.ACCESS_TOKEN, token, { secure: true, sameSite: 'lax' })
  }

  private _throwLogicalError (error: IHttpAuthLogicalError): ExceptionService<unknown> | undefined {
    if (error.message) {
      return ExceptionService.new({
        status: {
          code: Number(error.code),
          message: error.message
        },
        data: {
          logical: this._transformError(error.code)
        }
      })
    }

    return undefined
  }

  private _throwValidationError (error: IHttpAuthError): ExceptionService<unknown> {
    const data: IAuthErrors = {}

    if (error.email) data.email = this._transformError(error.email)
    if (error.password) data.password = this._transformError(error.password)
    if (error.code) data.code = this._transformError(error.code)
    if (error.codeWord) data.code = this._transformError(error.codeWord)

    return ExceptionService.new({
      status: {
        code: HttpCode.BAD_REQUEST,
        message: 'Backend bad request'
      },
      data
    })
  }

  private _transformError (code: AuthErrorCode): InternalCode | ValidationCode {
    switch (code) {
      case AuthErrorCode.USER_NOT_FOUND: return InternalCode.PROPERTY_NOT_FOUND
      case AuthErrorCode.USER_NEED_GA: return InternalCode.USER_NEED_GA_CODE
      case AuthErrorCode.USER_ALREADY_ENABLE_GA: return InternalCode.PROPERTY_IS_INVALID
      case AuthErrorCode.USER_ALREADY_DISABLE_GA: return InternalCode.PROPERTY_IS_INVALID
      case AuthErrorCode.USER_ALREADY_CONFIRMED: return InternalCode.USER_ALREADY_CONFIRMED
      case AuthErrorCode.INVALID_USER_DATA: return InternalCode.PROPERTY_ALREADY_EXIST
      case AuthErrorCode.INVALID_USER_STATUS: return InternalCode.INVALID_USER_STATUS
      case AuthErrorCode.INVALID_USER_CODE: return InternalCode.INVALID_USER_CODE
      case AuthErrorCode.INVALID_TOKEN: return InternalCode.PROPERTY_IS_INVALID
      case AuthErrorCode.INVALID_EMAIL: return ValidationCode.INVALID_EMAIL
      case AuthErrorCode.PROPERTY_IS_REQUIRED: return InternalCode.PROPERTY_IS_REQUIRED
      case AuthErrorCode.PROPERTY_IS_INVALID: return InternalCode.PROPERTY_IS_INVALID
      case AuthErrorCode.USER_CODE_ALREADY_SENDED: return InternalCode.USER_CODE_ALREADY_SENDED
      default: return InternalCode.VALIDATION_ERROR
    }
  }

}

export { AuthRepository }
