import { BalanceSocket } from '../../_'

import type { Stocks } from '@domain/common/enums'

import type { IOKXBalanceSocketResponse } from '../interfaces'
import type { IBalanceSocket } from '../../../interfaces'

import type { IBalance } from '@domain/stocks/account'

class OKXBalanceSocket extends BalanceSocket<IOKXBalanceSocketResponse> implements IBalanceSocket {

  constructor (stock: Stocks) {
    super('user-data-stream-', stock)
  }

  public override onMessage (callback: (message: IBalance[]) => void): void {
    this.socket?.onMessage((message) => {
      const balances: IBalance[] = message.data[0].details.map((item) => {
        return {
          asset: item.ccy,
          free: item.availBal,
          locked: item.frozenBal,
          all: item.cashBal
        }
      })

      callback(balances)
    })
  }

}

export { OKXBalanceSocket }
